/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import firebase from 'firebase/app';
import 'firebase/messaging';
import HighchartsVue from 'highcharts-vue'

Vue.component('v-select', vSelect)
Vue.use(HighchartsVue)

// If you don't need the styles, do not connect
Vue.use(VueSweetalert2);

axios.interceptors.response.use(null, (error) => {
    console.log(error.message)
    if (error.message === 'Network Error'){
        console.log('Network Error')
    }
    if (error.response.status == 401) {
      console.log('Token not valid')
      console.log(router.currentRoute.meta)
      if(router.currentRoute.meta.requiresAuth){
        localStorage.removeItem('tokensirulin')
        localStorage.removeItem('role')
        localStorage.removeItem('user_id')
        router.push({ name: 'login' })

      }
    }
    if (error.response.status == 500) {
        error.response.data.info = 'Terjadi kesalahan pada server, silahkan menghubungi admin'
        error.response.data.error = []
    }
    return Promise.reject(error)
})

// axios.defaults.baseURL = `http://116.0.2.210:8080/api-rujukan`
axios.defaults.baseURL = `https://api-rujukan.puribunda.com`

// axios.defaults.baseURL = `http://127.0.0.1:8000`
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("tokensirulin")

// axios.post("/api/refresh").then((response) => {
//     localStorage.setItem("role",response.data.user.role)
//     localStorage.setItem("user_id",response.data.user.id)
// });

Vue.config.productionTip = false

// // Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyB2Z6KSCVN6QAhvD9QyeQdaEVVYoTXsWBY",
    authDomain: "sirulin-15800.firebaseapp.com",
    projectId: "sirulin-15800",
    storageBucket: "sirulin-15800.appspot.com",
    messagingSenderId: "78539692720",
    appId: "1:78539692720:web:e5153eec10a510d1b87975",
    measurementId: "G-NNJGY5D7WM"
});
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('firebase-messaging-sw.js', {scope: "firebase-cloud-messaging-push-scope"})
    .then((registration) => {
        const messaging = firebase.messaging();
        messaging.useServiceWorker(registration);

    }).catch(err => {
        console.log(err)
    })
}

Vue.use(ArgonDashboard)
new Vue({
    router,
    axios,
    VueAxios,
    render: h => h(App)
}).$mount('#app')
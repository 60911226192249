import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

export default new Router({
    linkExactActiveClass: 'active',
    routes: [{
            path: '/',
            redirect: 'dashboard',
            component: DashboardLayout,
            children: [{
                    path: '/dashboard',
                    name: 'dashboard',
                    meta: { requiresAuth: true },
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/Dashboard.vue')
                },
                {
                    path: '/buat-rujukan',
                    name: 'Buat Rujukan',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanTambahRujukan.vue')
                },
                {
                    path: '/edit-rujukan/:id',
                    name: 'Edit Rujukan',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanEditRujukan.vue')
                },
                {
                    path: '/list-rujukan',
                    name: 'List Rujukan',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanRujukan.vue')
                },
                {
                    path: '/list-mitra',
                    name: 'List Mitra',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanMitra.vue')
                },
                {
                    path: '/list-user',
                    name: 'List User',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanUser.vue')
                },
                {
                    path: '/list-benefit',
                    name: 'List Benefit',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanBenefit.vue')
                },
                {
                    path: '/list-redeem',
                    name: 'List Redeem',
                    meta: { requiresAuth: true },
                    component: () =>
                        import ( /* webpackChunkName: "demo" */ './views/HalamanRedeem.vue')
                }
            ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [{
                path: '/login',
                name: 'login',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ './views/Login.vue')
            }]
        }
    ]
})
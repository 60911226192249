<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="SiRuLin"
      title="SiRuLin"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item v-if="role == '1'" :link="{name: 'Lihat List Mitra', icon: 'ni ni-single-02 text-blue', path: '/list-mitra'}"/>
        <sidebar-item v-if="role == '1'" :link="{name: 'Lihat List User', icon: 'ni ni-single-02 text-blue', path: '/list-user'}"/>
        <sidebar-item v-if="role == '1'" :link="{name: 'Lihat List Benefit', icon: 'ni ni-diamond text-yellow', path: '/list-benefit'}"/>

        <sidebar-item v-if="role == '2' || role == '1' || role == '5'" :link="{name: 'Lihat List Rujukan', icon: 'ni ni-pin-3 text-orange', path: '/list-rujukan'}"/>

        <sidebar-item v-if="role == '3' || role == '4' || role == '1'" :link="{name: 'Buat Rujukan', icon: 'ni ni-planet text-blue', path: '/buat-rujukan'}"/>
        <sidebar-item v-if="role == '3'" :link="{name: 'Lihat Status Rujukan', icon: 'ni ni-pin-3 text-orange', path: '/list-rujukan'}"/>
        <!-- <sidebar-item v-if="role == '3'" :link="{name: 'Profil', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/> -->

        <sidebar-item v-if="role == '4'" :link="{name: 'Lihat List Mitra', icon: 'ni ni-single-02 text-blue', path: '/list-mitra'}"/>
        <sidebar-item v-if="role == '4'" :link="{name: 'Lihat List Redeem Point', icon: 'ni ni-email-83 text-orange', path: '/list-redeem'}"/>
        <sidebar-item :link="{name: 'Logout', icon: 'ni ni-key-25 text-green', path: '/login'}" />

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
        role: '',
      };
    },
    mounted(){
      this.role = localStorage.getItem("role");
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      logout(){
        this.$http
        .post("/api/logout")
        .then(() => {
          localStorage.removeItem('tokensirulin')
          localStorage.removeItem('role')
          localStorage.removeItem('user_id')
          this.$router.push("/login")
        }).catch((err) => { 
          console.log(err)
        })
      }
    }
  };
</script>
<style lang="scss">
</style>

<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>

<script>
import firebase from 'firebase';
export default {
  created(){
    const messaging = firebase.messaging();
    messaging
        .requestPermission()
        .then(function () {
          console.log("Notification permission granted.");

          // get the token in the form of promise
          return messaging.getToken()
        })
        .then(function(token) {
          // print the token on the HTML page
          localStorage.setItem('fcm_token', token);
          // console.log("token is : " + token)
        })
        .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
    });
  }
}
</script>
